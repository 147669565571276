<template>
  <div class="work">
    <div class="container is-max-desktop">
      <div class="py-6">
        <p class="title is-1 has-text-centered has-text-black">Zoé Coffee</p>
        <nav
          class="breadcrumb is-small is-centered"
          aria-label="breadcrumbs"
        >
          <ul>
            <li><a @click="$router.push('/')">features</a></li>
            <!-- <li><a href="/work">work</a></li> -->
            <li class="is-active">
              <a href="#" aria-current="page">zoé-coffee</a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- <div class="markdown-body">
        <Demo />
      </div> -->
    </div>

    <!-- Cover Image -->
    <div class="container is-max-widescreen">
      <figure class="image">
        <img src="img/works/091/cover-2.jpg" />
      </figure>
    </div>

    <div class="my-6" />

    <!-- Product Overview -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 has-text-weight-bold has-text-black">
          Project Overview
        </p>
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Product Description
              </p>
              <div class="content">
                <p>
                  Zoé Coffee shop app is an online coffee ordering application. The idea is to make consumption more
                  convenient for consumers with this online system. Also the app can help on
                  increasing sales, providing more efficient services and enhancing
                  user satisfaction, branding out the coffee shop
                </p>
              </div>
            </article>
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Responsibility
              </p>
              <div class="content">
                <p>
                  Creating user-centered designs by understanding product
                  requirements, completing user research, conducting user
                  feedback. Building user flows, wireframes, Low and High
                  fidelity prototypes, Usability testing and studies, in my
                  design and mockups. Converting requirements into style guides,
                  design systems, design patterns, and attractive user
                  interfaces.
                </p>
              </div>
            </article>
          </div>
          <div class="tile is-vertical">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Goal
                  </p>
                  <div class="content">
                    <p>
                      Figure out all difficulties that users
                      ordering in the app
                    </p>
                  </div>
                </article>
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Role
                  </p>
                  <div class="content">
                    <p>UI/UX designer, Product designer</p>
                  </div>
                </article>
              </div>
              <div class="tile is-parent is-vertical">
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Duration
                  </p>
                  <div class="content">
                    <p>1 Month</p>
                  </div>
                </article>
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Tools
                  </p>
                  <div class="content">
                    <p>Figma, Sketch, Photoshop, Miro</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-6" />

    <!-- Understanding Users -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 has-text-weight-bold has-text-black">
          Understanding Users
        </p>
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <figure
                class="image"
                @click="
                  handleOpenActiveLightBox('img/works/091/thinking-process.jpg')
                "
              >
                <img src="img/works/091/thinking-process.jpg" />
              </figure>
              <!-- <p class="subtitle is-7 has-text-centered py-3">
                Click on image to enlarge
              </p> -->
            </article>
          </div>
        </div>

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                User Research
              </p>
            </article>
          </div>
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Pain Points
              </p>
              <div class="content">
                <p>
                  Here are some major pain points highlighted,
                </p>
                <ol class="skill">
                  <li>Ability order wanted drink quickly</li>
                  <li>Simply to easy, no ads or other confusing options</li>
                  <li>Long waiting hours before orders are ready</li>
                  <li>Incapable to make group orders</li>
                  <li>Flexible payment option</li>
                </ol>
              </div>

              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Improvement Keywords
              </p>
              <figure class="image">
                <img src="img/works/091/keywords.jpg" />
              </figure>
              <!-- <p class="subtitle is-7 has-text-centered py-3">
                Click on image to enlarge
              </p> -->
            </article>
          </div>
        </div>

        <div class="my-6" />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                Personas
              </p>
            </article>
          </div>
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <div class="content">
                <table class="table is-hoverable">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>Normand Kinsley</td>
                    </tr>
                    <tr>
                      <th>Education</th>
                      <td>Art & Design Degreey</td>
                    </tr>
                    <tr>
                      <th>Hometown</th>
                      <td>California US</td>
                    </tr>
                    <tr>
                      <th>Occupation</th>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <th>Age</th>
                      <td>32</td>
                    </tr>
                    <tr>
                      <th>Quote</th>
                      <td>
                        "Normal work pressure can sometimes be great. Relaxing
                        with a cup of coffee is my favourite thing to do in a
                        day"
                      </td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <td>
                        Normand is a designer. They usually need to spend a lot
                        of time to think about how to make a product that most
                        people like. They are very strict with themselves and do
                        things meticulously, which will occasionally put
                        pressure on people around them. Coffees, teas, or energy
                        drinks are essential for their day. In their spare time,
                        they like to go hiking or exercise to relieve stress
                      </td>
                    </tr>
                    <tr>
                      <th>Goals</th>
                      <td>
                        <ul>
                          <li>
                            They hope to make a product that most people like
                          </li>
                          <li>
                            They want to be a designer who pays more attention
                            to details
                          </li>
                          <li>
                            They hope to find a better way to relax in their
                            spare time
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>Frustrations</th>
                      <td>
                        <ul>
                          <li>
                            “Sometimes I hope it can remember my drink
                            preferences when placing an order, which can save a
                            lot of time”
                          </li>
                          <li>
                            “There are so many new type of drinks that can be
                            confusing sometimes”
                          </li>
                          <li>
                            “Not knowing the ingredients in the drink will make
                            me hesitate when placing an order”
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <figure class="image">
                <img src="img/works/091/personas.jpg" />
              </figure>
              <!-- <p class="subtitle is-7 has-text-centered py-3">
                Click on image to enlarge
              </p> -->
            </article>
          </div>
        </div>

        <div class="my-6" />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                User Flow / Journey Map
              </p>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="
                  handleOpenActiveLightBox(
                    'img/works/091/information-architecture.jpg'
                  )
                "
              >
                <img src="img/works/091/information-architecture.jpg" />
              </figure>
              <!-- <p class="subtitle is-7 has-text-centered py-3">
                Click on image to enlarge
              </p> -->
            </article>
          </div>
        </div>
      </div>
    </div>

    <!-- Design -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 has-text-weight-bold has-text-black">Design</p>
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                Wireframe & Prototype Design
              </p>
            </article>
          </div>
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Pain Point
              </p>
              <div class="content">
                <p>
                  Move onto prototyping. Start with Low Fidelity prototype to determine the outline of the site structure and layout, as well as displaying the basic user interface of the application to be made. The following is the wireframe of the Zoé Coffee shop app:
                </p>
              </div>
              <!-- <figure class="image" @click="handleOpenActiveLightBox('https://dummyimage.com/16:9x1080/f8f8f8/b0b0b0')">
                <img src="https://dummyimage.com/16:9x1080/f8f8f8/b0b0b0" />
              </figure>
              <p class="subtitle is-7 has-text-centered py-3">Click on image to enlarge</p> -->
            </article>
          </div>
        </div>

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/091/ux-0.jpg')"
              >
                <img src="img/works/091/ux-0.jpg" />
              </figure>
            </article>

            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/091/ux-0.jpg')"
              >
                <img src="img/works/091/ux-0.jpg" />
              </figure>
            </article>
          </div>
        </div>

        <div class="my-6" />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                Usability Studies
              </p>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="
                  handleOpenActiveLightBox('img/works/091/affinity-diagram.jpg')
                "
              >
                <img src="img/works/091/affinity-diagram.jpg" />
              </figure>
            </article>
          </div>
        </div>

        <div class="my-6" />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                User Interface Design
              </p>
            </article>
            <!-- <article class="tile is-child">
              <p>
                PAIN POINT We’re creating a Zoé Coffee shop app to make
                consumption more convenient for consumers with this online
                system. We want to increase sales, provide more efficient
                services and enhance user satisfaction, branding out our own
                coffee shop
              </p>
            </article>
            -->
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/091/ui-0.jpg')"
              >
                <img src="img/works/091/ui-0.jpg" />
              </figure>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/091/ui-1.jpg')"
              >
                <img src="img/works/091/ui-1.jpg" />
              </figure>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/091/ui-2.jpg')"
              >
                <img src="img/works/091/ui-2.jpg" />
              </figure>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/091/ui-3.jpg')"
              >
                <img src="img/works/091/ui-3.jpg" />
              </figure>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/091/ui-4.jpg')"
              >
                <img src="img/works/091/ui-4.jpg" />
              </figure>
            </article>
          </div>
        </div>
      </div>
    </div>

    <!-- Image light box modal -->
    <div class="modal" :class="{ 'is-active': isLightBoxActive }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <figure class="image">
          <img :src="lightBoxImageSrc" />
        </figure>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="handleCloseActiveLightBox"
      ></button>
    </div>

    <!-- Back to top -->
    <back-to-top bottom="50px" right="50px">
      <button class="button is-normal is-responsive">
        <span class="icon has-text-weight-bold has-text-black">UP</span>
      </button>
    </back-to-top>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
// import VueMarkdown from 'vue-markdown-render';
// import Demo from "./demo.md";

// 代码高亮
// import "highlight.js/styles/github.css";
// 其他元素使用 github 的样式
// import "github-markdown-css";

export default {
  name: "work",
  components: {
    Navbar,
  },
  data() {
    return {
      isLightBoxActive: false,
      lightBoxImageSrc: "",
    };
  },
  mounted() {
    this.track();
  },
  methods: {
    handleOpenActiveLightBox(src) {
      this.lightBoxImageSrc = src;
      this.isLightBoxActive = true;
    },
    handleCloseActiveLightBox() {
      this.lightBoxImageSrc = "";
      this.isLightBoxActive = false;
    },
    toIndexList() {
      // this.$router.push('/');
      // this.$router.reload();
      window.location.reload();
    },
    track() {
      this.$gtag.pageview("/zoe");
      this.$gtag.query('event', 'screen_view',{
        app_name: 'juliandesign.io',
        screen_name: 'Zoe',
      })
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 769px) {
  .modal-content,
  .modal-card {
    width: 80%;
  }
}
</style>
